@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/ngx-toastr/toastr"; // Standard Toastr colours
//@import '~ngx-toastr/toastr-bs4-alert';

// @import "~@aws-amplify/ui/src/Theme.css";
// @import "~@aws-amplify/ui/src/Angular.css";
//@import "~@aws-amplify/ui-angular/theme.css";

.table {
  --bs-table-bg: auto;
  --bs-table-color: auto;
}

.maintenance {
  height: 100%;
  width: 100%;
  background-image: url("/assets/maintenance.jpg");
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  overscroll-behavior-y: none;
  background-color: rgba(#203747, 1);
}

.amplify-form-signup {
  display: none;
}

span {
  color: unset;
}

.table {
  color: unset;
}
div.card {
  background-color: unset;
  color: unset;
}

.list-group {
  background-color: unset;
}

.list-group-item {
  background-color: unset;
}

svg {
  overflow: unset;
}

@import "@angular/material/theming";

@include mat-core();

// DRAGULA
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

@mixin common {
  .mat-form-field {
    margin-right: 12px;
  }

  .dialogFormField500 .mat-form-field-infix {
    width: 400px;
  }

  .mat-table {
    background-color: rgba(#000000, 0);
  }

  .mat-header-cell {
    padding-left: 4px;
    padding-right: 4px;
  }

  .mat-cell {
    padding-left: 4px;
    padding-right: 4px;
  }

  .mat-tree {
    background-color: rgba(#000000, 0);
  }

  a.mat-list-item.todo {
    color: rgba(#808080, 0.1);
  }

  a.mat-list-item.pending {
    color: rgba(#80ffff, 0.3);
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-option {
    font-size: small;
  }

  .mat-option-text {
    font-size: small;
  }

  .mat-select-placeholder {
    font-size: small;
  }

  .mat-select-value-text {
    font-size: small;
  }

  .mat-select-panel {
    max-height: 40vh;
  }
}

@mixin lightText {
  $disabled-text: $light-disabled-text;
  $secondary-text: $light-secondary-text;
  $primary-text: $light-primary-text;

  .list-group {
    color: $light-primary-text;
  }

  .app-version {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: $light-disabled-text;
    font-size: smaller;
  }

  .app-version-header {
    color: $light-secondary-text;
  }

  .analysis-header {
    font-size: large;
    font-weight: bold;
  }

  .analysis-rule-warning {
    color: darkorange;
  }

  .analysis-rule-ok {
    color: greenyellow;
  }

  .analysis-rule-skipped {
    color: $light-disabled-text;
  }

  .analysis-rule-error {
    color: red;
  }

  .analysis-rule-ignored {
    color: orange;
  }

  .analysis-rule-matched {
    color: $light-primary-text;
    font-weight: bold;
  }

  .analysis-rule-not-matched {
    color: $light-secondary-text;
  }

  .analysis-rule-name {
    color: $light-primary-text;
  }

  .analysis-rule-xpath {
    color: $light-primary-text;
  }

  .analysis-rule-value {
    color: yellow;
  }

  tr.matched-rule-whitelist {
    background-color: darkgreen;
  }

  tr.matched-rule-blacklist {
    background-color: darkred;
  }

  tr.matched-rule-notSupported {
    background-color: darkred;
  }

  tr.matched-rule-validation {
    background-color: darkorange;
  }

  .referenced {
    font-weight: bold;
    background-color: rgba(#ffffff, 0.2);
  }

  .unreferenced {
    font-weight: lighter;
    color: $disabled-text;
  }
}

@mixin darkText {
  $disabled-text: $dark-disabled-text;
  $secondary-text: $dark-secondary-text;
  $primary-text: $dark-primary-text;

  .list-group {
    color: $dark-primary-text;
  }

  .app-version {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: $dark-disabled-text;
    font-size: smaller;
  }

  .app-version-header {
    color: $dark-secondary-text;
  }

  .analysis-header {
    font-size: large;
    font-weight: bold;
  }

  .analysis-rule-warning {
    color: darkorange;
  }

  .analysis-rule-ok {
    color: green;
  }

  .analysis-rule-skipped {
    color: $dark-disabled-text;
  }

  .analysis-rule-error {
    color: red;
  }

  .analysis-rule-matched {
    color: $dark-primary-text;
    font-weight: bold;
  }

  .analysis-rule-not-matched {
    color: $dark-secondary-text;
  }

  .analysis-rule-name {
    color: $dark-primary-text;
  }

  .analysis-rule-xpath {
    color: $dark-primary-text;
  }

  .analysis-rule-value {
    color: blue;
  }

  tr.matched-rule {
    background-color: darkseagreen;
  }

  tr.bl-matched-rule {
    background-color: rosybrown;
  }

  tr.nsl-matched-rule {
    background-color: rosybrown;
  }

  tr.vl-matched-rule {
    background-color: lightsalmon;
  }

  .referenced {
    font-weight: bold;
    background-color: rgba(#000000, 0.1);
  }

  .unreferenced {
    font-weight: lighter;
    color: $disabled-text;
  }
}

.Storm {
  $storm-app-primary: mat-palette($mat-blue, 600, 200, 800);
  $storm-app-accent: mat-palette($mat-indigo, 600, 200, 900);
  $storm-app-warn: mat-palette($mat-red);
  $storm-app-theme: mat-dark-theme(
    $storm-app-primary,
    $storm-app-accent,
    $storm-app-warn
  );

  @include angular-material-theme($storm-app-theme);

  background-image: url("/assets/storm.jpg");
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  overscroll-behavior-y: none;
  background-color: rgba(#203747, 1);

  .jumbotron {
    background-color: rgba(#203747, 1);
  }

  .list-group {
    background-color: rgba(#203747, 1);
  }

  .mat-tab-header {
    background-color: rgba(#203747, 0.7);
    color: $light-primary-text;
  }

  .mat-app-background {
    background-color: rgba(#000000, 0);
  }

  .mat-drawer-container {
    background-color: rgba(#000000, 0);
  }

  .cdk-overlay-backdrop-showing {
    background-color: rgba(#203747, 0.2);
  }

  .cdk-overlay-container,
  .cdk-overlay-pane {
    z-index: 9999 !important;
  }

  .mat-drawer.mat-drawer-push {
    background-color: rgba(#203747, 1);
  }

  .mat-drawer.mat-drawer-side {
    background-color: rgba(#203747, 1);
  }

  .mat-drawer-side.mat-drawer-end {
    background-color: rgba(#203747, 0.75);
  }

  .mat-toolbar {
    background-color: rgba(#203747, 1);
  }

  .mat-expansion-panel {
    background-color: rgba(#203747, 0.5);
  }

  @include lightText;
  @include common;
}

.Aurora {
  $aurora-app-primary: mat-palette($mat-green, 600, 200, 800);
  $aurora-app-accent: mat-palette($mat-teal, 600, 200, 900);
  $aurora-app-warn: mat-palette($mat-red);
  $aurora-app-theme: mat-dark-theme(
    $aurora-app-primary,
    $aurora-app-accent,
    $aurora-app-warn
  );

  @include angular-material-theme($aurora-app-theme);

  background-image: url("/assets/aurora.jpg");
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  overscroll-behavior-y: none;
  background-color: rgba(#2e7d32, 1.8);

  .jumbotron {
    background-color: rgba(#2e7d32, 1.8);
  }

  .list-group {
    background-color: rgba(#2e7d32, 1.8);
  }

  .mat-tab-header {
    background-color: rgba(#1b5e20, 0.7);
    color: $light-primary-text;
  }

  .mat-app-background {
    background-color: rgba(#000000, 0);
  }

  .mat-drawer-container {
    background-color: rgba(#000000, 0);
  }

  .cdk-overlay-backdrop-showing {
    background-color: rgba(#2e7d32, 0.2);
  }

  .cdk-overlay-container,
  .cdk-overlay-pane {
    z-index: 9999 !important;
  }

  .mat-drawer.mat-drawer-push {
    background-color: rgba(#1b5e20, 1);
  }

  .mat-drawer.mat-drawer-side {
    background-color: rgba(#1b5e20, 1);
  }

  .mat-drawer-side.mat-drawer-end {
    background-color: rgba(#1b5e20, 0.75);
  }

  .mat-toolbar {
    background-color: rgba(#1b5e20, 1);
  }

  .mat-expansion-panel {
    background-color: rgba(#1b5e20, 0.5);
  }

  @include lightText;
  @include common;
}

.Clean {
  $clean-app-primary: mat-palette($mat-blue, 600, 200, 800);
  $clean-app-accent: mat-palette($mat-indigo, 600, 200, 900);
  $clean-app-warn: mat-palette($mat-red);
  $clean-app-theme: mat-light-theme(
    $clean-app-primary,
    $clean-app-accent,
    $clean-app-warn
  );

  @include angular-material-theme($clean-app-theme);

  @include darkText;
  @include common;
}

body,
html {
  min-height: 100%;
  height: 100%;
  margin: 0;
  padding: 0px;
  font-family: Roboto, sans-serif;
}

#main-content {
  padding: 16px 32px;
}

.storm-title {
  padding-left: 16px;
}

.storm-icon {
  padding-left: 8px;
  padding-top: 4px;
}

.storm-menu-button {
  padding-bottom: 8px;
}

.toolbar-spacer {
  flex: 1 1 auto;
}

fa-icon {
  width: 24px;
}

.signin-area {
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: aliceblue;
}

.amplify-component,
.amplify-authenticator {
  background-color: rgba(#000000, 0);
  box-shadow: 0px 0px;
}

p {
  padding-top: 16px;
  padding-bottom: 16px;
}

.amplify-greeting-text {
  width: 270px;
  text-align: end;
}

.amplify-greeting-flex-spacer {
  width: 24px;
}

.amplify-component {
  width: 400px;
}

.pdfhtmldiff {
  color: #2f2f2f;;
  overflow: scroll;
}

.removed {
  color: #d60000;
  text-decoration: line-through;
}

.added {
  color: yellow;
}

.rules-tooltip {
  min-width: 450px;
  max-width: 550px;
  max-height: 6000px;
}

.download-rules-tooltip {
  min-width: 350px;
}

.beta {
  color: #4caf50;
}

.app-version {
  pointer-events: none;
  width: 190px;
}

.original-in-monaco-diff-editor::after,
.original-in-monaco-diff-editor::before {
  background: #ecf0f1;
  color: #000;
  padding: 5px 10px;
  content: "Expected";
  position: absolute;
  top: 5px;
  right: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  z-index: 300;
}

.modified-in-monaco-diff-editor::after,
.modified-in-monaco-diff-editor::before {
  background: #ecf0f1;
  color: #000;
  padding: 5px 10px;
  content: "Actual";
  position: absolute;
  top: 5px;
  right: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  z-index: 300;
}

.mat-dialog-without-padding {
  mat-dialog-container {
    padding: 0;
  }
}

mat-toolbar {
  position: sticky;
  top: 0;
  z-index: 999;
}

body .mat-drawer-inner-container {
  overflow-y: auto;
  overflow-x: hidden;
}

// Tweak Bootstrap's tooltip
.tooltip-inner {
  border: solid 1px #ccc;
}

//// * ng-bootstrap modal styling * /////

//modal animation
.modal-content {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5);
    // }
    // 75% {
    //   transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}

//modal custom styling
.json-editor-modal {
  .modal-content {
    // background-color: #142c3b;
    // color: white;
    height: 95vh !important;
    width: 95vw !important;
  }
}

.auto-transform-modal {
  .modal-content {
    width: 80vw !important;
  }
}
//

///////////////* json editor styling *////////////

//import css file for the json editor used in components/manual-drafting/payload-editor component
//@import "~jsoneditor/dist/jsoneditor.min.css";

.monaco-wrapper {
  height: 100%;
  width: 100%;
  //min-height: 600px;
}

.monaco-editor {
  height: 100%;
  width: 100%;
}

.editor-container {
  height: 100%;
  // min-height: 600px;
}

// Bootstrap 5 polyfill

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

@media (min-width: 576px){
  .form-inline .form-group {
      flex: 0 0 auto;
      flex-flow: row wrap;
  }
  }
  
  @media (min-width: 576px){
  .form-inline .form-group, .form-inline label {
      display: flex;
      align-items: center;
      margin-bottom: 0;
  }
  }
  
  @media (min-width: 576px){
  .form-inline .custom-select, .form-inline .input-group {
      width: auto;
  }
  }

.form-group {
  margin-bottom: 1rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}


.form-row {
  align-items: end;
}

.form-row > .col, .form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-row.centered {
  align-items: center;
}


.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

button.close {
  padding: 0;
  background-color: initial;
  border: 0;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.btn-info {
  color: #fff !important;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}


// MATERIAL POLYFILL

// .mat-raised-button {
//   line-height: unset;
// }

.mat-input-element {
  -webkit-appearance: none;
  background: transparent;
  color: currentColor;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
  vertical-align: bottom;
  text-align: inherit;
}


